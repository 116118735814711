import { useMutation } from '@apollo/client/index.js';
import ForgottenPasswordForm, {
  FormValues,
  initialValues,
  validate as formValidate,
} from '@asaprint/asap/components/forms/ForgottenPasswordForm.js';
import {
  FORGOTTEN_PASSWORD_BACK_TO_SIGN_IN,
  FORGOTTEN_PASSWORD_DESCRIPTION,
  FORGOTTEN_PASSWORD_EMAIL_HAS_BEEN_SENT,
  FORGOTTEN_PASSWORD_RESET,
} from '@asaprint/asap/locales/client.js';
import { DASHBOARD_ROUTE, AUTH_LOGIN_ROUTE } from '@asaprint/asap/routes.js';
import { ForgottenPassword_Save } from '@asaprint/asap/routes/auth/forgotten-password.graphql';
import {
  ForgottenPassword_SaveMutation,
  ForgottenPassword_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Box, Button, LinearProgress, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink, redirect, useNavigate } from 'react-router-dom';

const logger = getLogger('@intranet/routes/auth/forgotten-password');

interface Props {}

const ForgottenPasswordPage: React.FunctionComponent<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useLocale();
  const [forgottenPasswordExecute, { data, error }] = useMutation<
    ForgottenPassword_SaveMutation,
    ForgottenPassword_SaveMutationVariables
  >(ForgottenPassword_Save);

  const onSubmit: OnSubmit<FormValues> = async ({ usernameOrEmail }: FormValues) => {
    try {
      const response = await forgottenPasswordExecute({ variables: { usernameOrEmail } });

      // Always pretend that we send email with instructions so that
      // we do not open which email addresses exist in the system
      enqueueSnackbar(t(FORGOTTEN_PASSWORD_EMAIL_HAS_BEEN_SENT), {
        variant: 'success',
      });
      // Redirect
      navigate(AUTH_LOGIN_ROUTE);
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  return (
    <>
      <p>{t(FORGOTTEN_PASSWORD_DESCRIPTION)}</p>
      <Form defaultValues={initialValues} onSubmit={onSubmit} validate={formValidate}>
        {({ formState: { isSubmitting } }) => (
          <Box sx={{ mt: 2 }}>
            <ForgottenPasswordForm />
            <Box sx={{ mt: 3, mb: 2 }}>
              {isSubmitting && <LinearProgress sx={{ my: 1 }} />}
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                {t(FORGOTTEN_PASSWORD_RESET)}
              </Button>
            </Box>
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              <Link variant="body2" component={RouterLink} to={AUTH_LOGIN_ROUTE}>
                {t(FORGOTTEN_PASSWORD_BACK_TO_SIGN_IN)}
              </Link>
            </Box>
          </Box>
        )}
      </Form>
    </>
  );
};

ForgottenPasswordPage.displayName = 'ForgottenPasswordPage';

export default React.memo<Props>(ForgottenPasswordPage);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    if (req.user) {
      return redirect((req.query.next as string) ?? DASHBOARD_ROUTE);
    }
  }
  return null;
};
