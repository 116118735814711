
            import createOneQuery from '@engined/core/build/graphql-plugin-lib';
            
            const doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ForgottenPassword_Save"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"usernameOrEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forgottenPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"usernameOrEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"usernameOrEmail"}}}],"directives":[]}]}}],"loc":{"start":0,"end":138}};
            doc.loc.source = {"body":"\n          mutation ForgottenPassword_Save($usernameOrEmail: String!) {\n  forgottenPassword(usernameOrEmail: $usernameOrEmail)\n}\n\n        ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
          

    const names = new Set<string>();
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          const name = def.name.value
          if (names.has(name)) {
            return false;
          } else {
            names.add(name);
            return true;
          }
        }
      )
    }
  

              const oneQuery = createOneQuery(doc);
              export default doc;
            
                  export const ForgottenPassword_Save = oneQuery(doc, "ForgottenPassword_Save");
                
